import theme from './theme';
import { transition, placeholder } from './mixin';

const Daterangepicker = `
  .daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
    padding: 0px;
    background: #FFF;
  }
  .daterangepicker .calendar-table th {
    color: #464646;
  }
  .daterangepicker ul > li:before {
    display: none;
  }
  .daterangepicker .ranges{
    margin: 4px;
  }
  .daterangepicker .ranges li {
    font-size: 13px;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: #536A7F;
    padding: 3px 12px;
    margin-bottom: 4px;
    border-radius: 5px;
    cursor: pointer;
    &:last-child{
      margin-bottom: 0px;
    }
  }
  .daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
    background: #536A7F;
    border: 1px solid #536A7F;
    color: #fff;
  }
  .daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #536A7F;
    color: #fff;
  }
  .daterangepicker td.in-range {
    background: #E4E7EA;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    &.start-date{
      border-radius: 4px 0 0 4px;
      background-color: #536A7F;
    }
    &.end-date {
      border-radius: 0 4px 4px 0;
      background-color: #536A7F;
    }
  }
  .daterangepicker td.disabled, .daterangepicker option.disabled {
    text-decoration: none;
  }
`;
export default Daterangepicker;
