import theme from '/style/theme';

const LightGallery = `
body {
  .lg-backdrop{background-color:rgba(0,0,0,0.5) !important}
  .lg-sub-html, .lg-toolbar{background:none !important}
  .lg-sub-html{padding:0 !important;background-color: none !important}
  .lg-outer .lg{max-width:1024px;max-height:520px;top:50%;transform:translateY(-50%);-moz-transform:translateY(-50%);-webkit-transform:translateY(-50%);border-radius:5px;box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3)}
  .lg-outer .lg-inner{border-top-left-radius:5px; background-color: ${theme.dark_gy};}
  .lg-outer .lg-img-wrap{width:65%;height:calc(100% - 70px);height:-moz-calc(100% - 70px);height:-webkit-calc(100% - 70px);padding:0px}
  .lg-outer .lg-sub-html{position:absolute;left:auto;right:0px;top:0px;bottom:0px !important;width:35%;height:calc(100% - 70px);height:-moz-calc(100% - 70px);height:-webkit-calc(100% - 70px);background-color:#FFFFFF !important;text-align:left;border-top-right-radius:5px}
  .lg-toolbar .lg-close{width:38px;height:38px;padding:0px;background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/btn-close-sgy.png);margin-top:2px;margin-right:2px}
  .lg-toolbar .lg-close:after{display:none}
  .lg-outer .lg-thumb{padding:0px !important;margin-bottom:0px !important;margin-left: 0px}
  .lg-outer .lg-thumb-outer{overflow:hidden;white-space:nowrap;padding:0px;border-radius:0px;border-top:1px solid #eaeaea;border-bottom-left-radius:5px;border-bottom-right-radius:5px;background: repeating-linear-gradient(to right, #f3f3f3, #f3f3f3 70px, #ffffff 1px, #f3f3f3 71px); background-position:-37px 0;-webkit-transform: translate3d(0,0,0);transform: translate3d(0,0,0); line-height: 0;}
  .lg-outer .lg-thumb-item{border:none !important;border-radius:0px !important;margin-right:1px !important;width:70px !important;height:70px !important;margin-bottom:0px !important;vertical-align:top !important;position:relative}
  .lg-outer .lg-thumb-item.active:after, .lg-outer .lg-thumb-item:hover:after{content:"";display:block;position:absolute;top:0px;left:0px;right:0px;bottom:0px;border: 3px solid #d8f3f2;z-index:1}
  .lg-thumb.lg-group{height:70px !important;display:inline-flex !important;left:37px;padding-left:37px !important}
  .lg-outer .lg-toogle-thumb, .lg-autoplay-button, .lg-fullscreen{display:none !important}
  .photo_review_box{font-size:14px;height:100%}
  .photo_review_box_top{padding:15px 0 15px 20px;position:relative} 
  .photo_review_box_top:after{content:"";position:absolute;border-bottom:1px solid #eaeaea;height:1px;width:100%;left:0px;bottom:0px;display:block}
  .photo_review_box_bottom{padding:20px;height:calc(100% - 68px);height:-moz-calc(100% - 68px);height:-webkit-calc(100% - 68px)}
  .photo_review_user_pic{width:36px;height:36px;display:inline-block;background-size:cover;border-radius:40px;vertical-align:top}
  .photo_review_user_info{display:inline-block;margin-left:10px;vertical-align:top;letter-spacing:-0.19px;width:calc(100% - 80px);width:-moz-calc(100% - 80px);width:-webkit-calc(100% - 80px)}
  .photo_review_user_name{color:#464646;font-weight:600;line-height:24px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis}
  .photo_review_country{color:#666666;font-size:12px;line-height:14px}
  .photo_review_time{font-size:12px;line-height:14px;color:#666666;letter-spacing:-0.2px;float:right}
  .photo_review_text{color:#666666;font-size:14px;line-height:23px;letter-spacing:-0.2px;margin-top:20px;height:calc(100% - 35px);height:-moz-calc(100% - 35px);height:-webkit-calc(100% - 35px);overflow:auto;}
  .photo_review_rating{float:left;height:15px}
  .photo_review_rating .heart{position:relative;background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/img_big_heart.png);background-size:cover;width:18px;height:15px;margin-left:0;margin-right:5px;display:inline-block}
  .photo_review_rating .heart .heart_on{background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/img_big_heart.png);background-position:-18px 0;width:18px;height:15px;background-repeat:no-repeat}
  .arrow-pr{height: 70px;width:37px;display:inline-block;vertical-align:top;background:#ffffff;cursor:pointer;background-repeat:no-repeat}
  .arrow-pr.prev-arrow-pr{border-bottom-left-radius:5px;position:absolute;left:0;z-index:1;background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/arrow-gy-left.png);background-position:center center}
  .arrow-pr.next-arrow-pr{position:absolute;right:0;float:right;border-bottom-right-radius:5px;background-position:-114px 0;z-index:1;background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/arrow-gy-right.png);background-position:center center}
  @media ${theme.md_max} {
    .lg-outer .lg {
      max-width: calc(100% - 40px);
    }
  }
  @media ${theme.xs_max} {
    .lg-outer { background-color: ${theme.dark_gy} }
    .lg-outer .lg{max-width:100%;max-height:100%;border-radius:0px;top:0px;transform:none;overflow-x: hidden; overflow-y: auto; left:0; right: 0;}
    .lg-outer .lg-inner{background-color: ${theme.dark_gy};border-radius:0px}
    .lg-outer .lg-img-wrap{width:100%;height:400px;background-color: ${theme.dark_gy}}
    .lg-outer .lg-sub-html{position:relative;left:auto;top:400px;bottom:auto !important;right:auto;width:100%;border-top-right-radius:0px;height:auto}
    .lg-outer .lg-thumb-outer{border-bottom-left-radius:0px;border-bottom-right-radius:0px;position:fixed;bottom:0px}
    .lg-toolbar .lg-close{background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/mobile/btn_close_swh%402x.png);background-size:cover}
    .photo_review_box,.photo_review_box_bottom,.photo_review_text{height:auto;overflow:inherit}
    .photo_review_box_bottom{padding-bottom:90px}
    .arrow-pr.prev-arrow-pr {
      background-image: url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/mobile/arrow-gy-left@2x.png);
      background-size: 38px 38px;
      border-bottom-left-radius: 0;
    }
    .arrow-pr.next-arrow-pr {
      background-image: url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/mobile/arrow-gy-right@2x.png);
      background-size: 38px 38px;
      border-bottom-right-radius: 0;
    }
    .photo_review_rating .heart{position:relative;background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/mobile/img_big_heart@2x.png);background-size: 54px 15px;}
    .photo_review_rating .heart .heart_on{background-image:url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/mobile/img_big_heart@2x.png);background-size: 54px 15px;}
  }
}
`;

export default LightGallery;
