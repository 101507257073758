import theme from './theme';

const Normalize = `
  @font-face{font-family:'icons_mobiscroll';src:url('/fonts/icons_mobiscroll.eot?oowi3q');src:url('/fonts/icons_mobiscroll.eot?#iefixoowi3q') format('embedded-opentype'),url('/fonts/icons_mobiscroll.ttf?oowi3q') format('truetype'),url('/fonts/icons_mobiscroll.woff?oowi3q') format('woff'),url('/fonts/icons_mobiscroll.woff') format('woff'),url('/fonts/icons_mobiscroll.svg?oowi3q#icons_mobiscroll') format('svg');font-style:normal}
  /* normalize */
  html {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  body {
    overflow-x: hidden;
    margin: 0;
  }
  
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  menu,
  nav,
  section,
  summary {
    display: block;
  }
  
  audio,
  canvas,
  progress,
  video {
  }
  
  audio:not([controls]) {
    display: none;
    height: 0;
  }
  
  [hidden],
  template {
    display: none;
  }
  
  a {
    background-color: transparent;
  }
  
  browsers. a:active,
  a:hover {
    // outline: 0;
  }
  
  abbr[title] {
    border-bottom: 1px dotted;
  }
  
  b,
  strong {
    font-weight: ${theme.bold};
  }
  
  dfn {
    font-style: italic;
  }
  
  mark {
    background: #ff0;
    color: #000;
  }
  
  small {
    font-size: 80%;
  }
  
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  
  sup {
    top: -0.5em;
  }
  
  sub {
    bottom: -0.25em;
  }
  
  img {
    border: 0;
  }
  
  svg:not(:root) {
    overflow: hidden;
  }
  
  figure {
    margin: 1em 40px;
  }
  
  hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
  }
  
  pre {
    overflow: auto;
  }
  
  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  
  button,
  input,
  optgroup,
  select,
  textarea {
    // outline: none;
  }
  
  button {
    outline: 0;
    overflow: visible;
    border: 0;
    background-color: transparent;
  }
  
  button,
  select {
    text-transform: none;
  }
  
  button,
  input[type="reset"],
  input[type="submit"] {
    cursor: pointer;
  }
  
  button[disabled],
  html input[disabled] {
    cursor: default;
  }
  
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  
  input {
    line-height: normal;
  }
  
  input[type="checkbox"],
  input[type="radio"] {
  }
  
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  
  input[type="search"] {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
  }
  
  legend {
  }
  
  textarea {
    overflow: auto;
  }
  
  optgroup {
    font-weight: 500;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  td,
  th {
    padding: 0;
  }
  strong,
  dt,
  kbd kbd,
  label,
  .label {
    font-weight: 600;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    small {
      font-weight: 400;
    }
  }
  /* init */
  * {
    font-family: ${theme.sans_serif};
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
            box-sizing: border-box;
  }
  html {
    width: 100%;
    min-height: 100%;
    position: relative;
  }
  body {
    width: 100%;
    background-color: ${theme.body_bg};
    font-family: ${theme.sans_serif};
    margin: 0;
    ${theme.p_style};
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  // a:focus, a:hover{
    // color: inherit;
  // }
  ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
  }
  em, address { font-style: normal;}
  fieldset {
    margin: 0;
    padding: 0;
    border: 0
  }
  input,
  input[type="search"],
  input[type="text"] {
  -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
          box-sizing: border-box;
    &:before,
    &:after {
      -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
              box-sizing: border-box;
    }
  }
  input[disabled] {
    background-color: #eee;
  }
  select {
    border-radius: 0;
  }
  /* hide visually for screen reader */
  .screen-reader-text,
  .sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    clip: rect(0,0,0,0);
  }
  /* show on mobile */
  .show-mobile {
    @media ${theme.sm} {
      display: none!important;
    }
  }
  .hide-mobile {
    display: none!important;
    @media ${theme.sm} {
      display: block!important;
    }
  }
  .hide-mobile_inline-block {
    display: none!important;
    @media ${theme.sm} {
      display: inline-block!important;
    }
  }
  .hide-md-max {
    display: block;
    @media ${theme.md_max} {
      display: none!important;
    }
  }
`;

export default Normalize;
