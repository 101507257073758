import {
  SWEETALERT,
  LOADING,
  SCREENWIDTH,
  SUBMITTING,
  MODALMEMBER,
  MODALREVIEW,
  MODALSPOT,
  MODALPHOTO,
  PHOTOSWIPE,
  OPENCART,
} from '../types';

const initialState = {
  screenwidth: 'xs',
  sweetalert: {
    show: false,
    title: ' ',
    text: '',
    type: 'error',
  },
  loading: false,
  submitting: false,
  modalmember: {
    show: false,
    type: 'login',
  },
  modalspot: {
    show: false,
    type: 'basic',
    initial_value: {},
  },
  modalphoto: {
    show: false,
    initial_value: {},
  },
  modalreview: {
    show: false,
  },
  photoswipe: {
    show: false,
    index: 0,
    items: [],
  },
  opencart: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SWEETALERT:
      if (typeof action.payload === 'string') {
        // when param is only message
        const title = action.payload;
        action.payload = {};
        action.payload.title = title;
        action.payload.show = true;
      } else {
        if (action.payload.show === false) {
          // when closing popup, reset with initialState
          return Object.assign({}, state, {
            sweetalert: initialState.sweetalert,
          });
        }
      }
      // when opening popup, show popup with payload data
      return Object.assign({}, state, {
        sweetalert: Object.assign({}, state.sweetalert, action.payload),
      });

    case LOADING:
      return Object.assign({}, state, { loading: action.payload });
    case SCREENWIDTH:
      return Object.assign({}, state, { screenwidth: action.payload });
    case OPENCART:
      return Object.assign({}, state, { opencart: action.payload });
    case SUBMITTING:
      return Object.assign({}, state, { submitting: action.payload });
    case MODALMEMBER:
      if (!action.payload.show) {
        // when closing popup, reset with initialState
        return Object.assign({}, state, {
          modalmember: initialState.modalmember,
        });
      }
      // when opening popup, show popup with payload data
      return Object.assign({}, state, {
        modalmember: Object.assign({}, state.modalmember, action.payload),
      });
    case MODALSPOT:
      if (!action.payload.show) {
        // when closing popup, reset with initialState
        return Object.assign({}, state, {
          modalspot: initialState.modalspot,
        });
      }
      // when opening popup, show popup with payload data
      return Object.assign({}, state, {
        modalspot: Object.assign({}, state.modalspot, action.payload),
      });
    case MODALPHOTO:
      if (!action.payload.show) {
        // when closing popup, reset with initialState
        return Object.assign({}, state, {
          modalphoto: initialState.modalphoto,
        });
      }
      // when opening popup, show popup with payload data
      return Object.assign({}, state, {
        modalphoto: Object.assign({}, state.modalphoto, action.payload),
      });
    case PHOTOSWIPE:
      if (!action.payload.show) {
        // when closing popup, reset with initialState
        return Object.assign({}, state, {
          photoswipe: initialState.photoswipe,
        });
      }
      // when opening popup, show popup with payload data
      return Object.assign({}, state, {
        photoswipe: Object.assign({}, state.photoswipe, action.payload),
      });
    default:
      return state;
  }
};
