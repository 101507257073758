import { TRANSLATION } from '../types';

const initialState = '';

export default (state = initialState, action) => {
  
  switch (action.type) {
    case TRANSLATION:
      if (state === action.payload) return initialState; // return to english if user click active language button
      return action.payload;
    default:
      return state;
  }
};
