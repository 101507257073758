import { AUTHENTICATE_COMPANY, DEAUTHENTICATE_COMPANY, USER_COMPANY } from '../types';

interface ModelUser {
  affiliate_id: string;
  company_id: number;
  company_name: string;
  custom_url: string;
  page_code: number;
  password: string;
  status: number;
  username: string;
}

interface AuthState {
  token: string | null;
  user: ModelUser | null;
}

interface AuthAction {
  type: string;
  payload: string | ModelUser | null;
}

const initial_state = {
  token: null,
  user: null,
};

export default (state: AuthState = initial_state, action: AuthAction) => {
  switch (action.type) {
    case AUTHENTICATE_COMPANY:
      return { ...state, token: action.payload };
    case USER_COMPANY:
      return { ...state, user: action.payload };
    case DEAUTHENTICATE_COMPANY:
      return { token: null, user: null };
    default:
      return state;
  }
};
