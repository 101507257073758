import { SUBDOMAIN } from '../types';

const initialState = ''

export default (state = initialState, action) => {
  switch (action.type) {
    case SUBDOMAIN:
      return action.payload;
    default:
      return state;
  }
};
