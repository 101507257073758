import getSubDomain from './getSubDomain';

const checkBusinessBlockedPage = path => {
  const blocked_pages = ['/affiliate', '/point', '/mypoint', '/wishlist'];
  return blocked_pages.some(str => path.startsWith(str));
};
const redirectInGetInitialProps = (ctx, locale) => {
  const subdomain = getSubDomain(ctx);
  const locale_path = locale == 'en' ? '' : `/${locale}`;
  if (ctx.req) {
    const path = ctx.asPath.replace(`/${locale}`, '');
    if (path !== '/' && path.slice(-1) === '/') {
      ctx.res.writeHead(301, {
        Location: locale_path + path.slice(0, -1),
      });
      ctx.res.end();
    }
    if (path.toLowerCase().indexOf('/igth') === 0) {
      ctx.res.writeHead(302, {
        Location: `${locale_path}/things-to-do/thailand?ch=0030&label=102919%20bio&link_id=9941`,
      });
      ctx.res.end();
    } else if (path.toLowerCase().indexOf('/igkr') === 0) {
      ctx.res.writeHead(302, {
        Location: `/${locale != 'en' ? locale : ``}?ch=0029&label=bio102919&link_id=9909`,
      });
      ctx.res.end();
    } else if (path.indexOf('/campaign/thai-island-paradise') === 0) {
      ctx.res.writeHead(302, {
        Location: path.replace(`/campaign/thai-island-paradise`, `${locale_path}/campaign/thailand-island-finder`),
      });
      ctx.res.end();
    } else if (path.indexOf('/newsletter') === 0) {
      ctx.res.writeHead(302, {
        Location: path.replace('/newsletter', `${locale_path}/getting-adjusted`),
      });
      ctx.res.end();
    } else if (subdomain === 'business' && checkBusinessBlockedPage(path)) {
      ctx.res.writeHead(302, {
        Location: `/${locale != 'en' ? locale : ``}`,
      });
      ctx.res.end();
    }
  } else {
    const path = window.location.pathname.replace(`/${locale}`, '');
    if (path !== '/' && path.slice(-1) === '/') {
      window.location.href = locale_path + path.slice(0, -1);
    }
    if (path.toLowerCase().indexOf('/igth') === 0) {
      window.location.href = `${locale_path}/things-to-do/thailand?ch=0030&label=102919%20bio&link_id=9941`;
    } else if (path.toLowerCase().indexOf('/igkr') === 0) {
      window.location.href = `/${locale != 'en' ? locale : ``}?ch=0029&label=bio102919&link_id=9909`;
    } else if (path.indexOf('/campaign/thai-island-paradise') === 0) {
      window.location.href = path.replace('/campaign/thai-island-paradise', `${locale_path}/campaign/thailand-island-finder`);
    } else if (path.indexOf('/newsletter') === 0) {
      window.location.href = path.replace('/newsletter', `${locale_path}/getting-adjusted`);
    } else if (subdomain === 'business' && checkBusinessBlockedPage(path)) {
      window.location.href = `/${locale != 'en' ? locale : ``}`;
    }
  }
};
export default redirectInGetInitialProps;
