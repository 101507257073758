import theme from './theme';
import { transition, placeholder } from './mixin';

const Datepicker = `
input.datepicker,
.datepicker {
  cursor: pointer;
  background-image: url('/images/ui/ico-datepicker.svg');
  background-position: 97% center;
  background-repeat: no-repeat;
  color: ${theme.brand};
  &.has_value {
    background-image: url('/images/ui/ico-datepicker_active.svg');
  }
  &.datepicker-popup {
    font-size: 14px;
  }
}
.react-datepicker {
  background-color: #ffffff;
  width: 329px;
  padding: 0px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  border: none;
  .react-datepicker__input-container {
    input[type="text"]{
      color: ${theme.brand};
      &:focus,
      &:active {
        border-color: ${theme.brand};
        background-image: url('/images/ui/ico-datepicker_active.svg');
        ${placeholder(theme.brand)};
      }
    }
  }
  .react-datepicker-popper[data-placement^="bottom"],
  .react-datepicker-popper[data-placement^="top"] {
    margin: 0;
  }
  .react-datepicker__month-container {
    float: none;
  }
  .react-datepicker__header {
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  .react-datepicker__nav {
    position: relative;
    background: #f3785d;
    color: #666666;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 20px 0 20px 0;
  }
  .react-datepicker__day-names {
    margin-top: 10px;
  }
  .react-datepicker__year {
    font-size: 15px;
    font-weight: ${theme.medi};
    color: #f3d0c8;
    margin-left: 10px;
  }
  .react-datepicker__month {
    margin: 0 0 10px;
  }
  .react-datepicker__selectwrap {
    vertical-align: middle;
    display: inline-block;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    background: #f4856b url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/arrow_month_selector.png) no-repeat;
    background-position: 60px 10px;
  }
  .react-datepicker__select {
    width: 98px;
    height: 53px;
    background: transparent;
    border: 0;
    font-size: 20px;
    color: #fff;
    font-weight: ${theme.medi};
    text-transform: uppercase;
    padding: 15px;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    option {
      color: ${theme.dark_gy};
    }
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 34px;
    height: 34px;
    line-height: 34px;
    padding: 0;
    margin: 1px 6px;
  }
  .react-datepicker__day-name {
    text-indent: 8px;
    text-align: center;
    font-weight: ${theme.medi};
    border: 0;
    visibility:hidden;
    &:first-letter {
      visibility:visible;
    }
    &:nth-child(6) { // sat
      color: #6cc9c9;
    }
    &:nth-child(7) { // sun
      color: #f05634;
    }
  }
  .react-datepicker__day {
    border: none;
    color: ${theme.mid_gy};
    font-size: 14px;
    font-weight: ${theme.medi};
    text-align: center;
    background-color: #fff;
    border-radius: 34px;
    outline: 0;
    ${transition()}
    &:hover,
    &.selected,
    &--selected {
      color: #fff;
      background-color: ${theme.brand};
      border-radius: 34px;
    }
    &--disabled {
      color: ${theme.line_gy}
      font-weight: ${theme.normal};
      &:hover {
        color: ${theme.line_gy};
        background-color: #fff;
      }
    }
    &--outside-month {
      visibility: hidden;
      text-indent: -9999px;
    }
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__prev,
  .react-datepicker__next {
    position: absolute;
    top: 50%;
    margin: -19px 0 0;
    height: 38px;
    width: 38px;
    display: block;
    padding: 0;
    cursor: pointer;
    text-indent: -9999px;
  }
  .react-datepicker__prev {
    background-image: url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/arrow-img-multi-01.png);
    background-position: -76px -38px;
    left: 5px;
  }
  .react-datepicker__next {
    background-image: url(https://d2ifd8cfqxdvq1.cloudfront.net/images/experience/v3/arrow-img-multi-01.png);
    background-position: -114px -38px;
    right: 5px;
  }
}


`;
export default Datepicker;
