const isCrawler = ctx => {
  let ua = '';
  if (ctx && ctx.req) {
    ua = ctx.req.headers['user-agent'];
  } else {
    ua = navigator.userAgent;
  }
  let is_crawler = false;
  const crawler_agent_regex = /bot|google|aolbuild|baidu|bing|msn|duckduckgo|teoma|slurp|yandex/i;
  if (crawler_agent_regex.test(ua)) {
    is_crawler = true;
  }
  return is_crawler;
};
export default isCrawler;
