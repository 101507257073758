import {
  AUTHENTICATE_PARTNER,
  DEAUTHENTICATE_PARTNER,
  USER_PARTNER,
} from '../types';

const initialState = {
  token: null,
  user: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_PARTNER:
      return Object.assign({}, state, { token: action.payload });
    case USER_PARTNER:
      return Object.assign({}, state, { user: action.payload });
    case DEAUTHENTICATE_PARTNER:
      return { token: null, user: null };
    default:
      return state;
  }
};
