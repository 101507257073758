const editor = `
  .editor-style {
    table {
      border: 0;
    }
    p {
      font-size: 15px;
      line-height: 24px;
      color: #666;
    }
  }
`;
export default editor;
