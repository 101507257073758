import { SETSUMPOINT } from '../types';

const initialState = 0;

export default (state = initialState, action) => {
  switch (action.type) {
    case SETSUMPOINT:
      if (!action.payload) return initialState;
      return action.payload;
    default:
      return state;
  }
};
