import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
// import requestMiddleware from "./middleware/requestMiddleware";

const middlewares = [thunk]; // add middleware
const enhancer =
  process.env.NODE_ENV === 'production'
    ? compose(applyMiddleware(...middlewares))
    : composeWithDevTools(applyMiddleware(...middlewares));

export default initialState => {
  return createStore(reducer, initialState, enhancer);
};
